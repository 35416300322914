(function(){
    'use strict';

    angular.module('adminApp')
        .controller("OrganizationsTabCtrl", OrganizationsTabController);

    OrganizationsTabController.$inject = ["$scope", "$filter", "$state", "aerosAdminApi", "createNewOrganizationModal",
        "$uibModal", "Notification", "OrganizationService", "$templateCache", "routesConstant"];

    function OrganizationsTabController($scope, $filter, $state, aerosAdminApi, createNewOrganizationModal, $uibModal,
                                        Notification, OrganizationService, $templateCache, routesConstant) {

        var ADMIN = $scope.ADMIN = routesConstant.ADMIN;

        (function prepareAgGrid() {

            var organizationListActionTemplate = $templateCache.get('organizationListActions.html');
            var featuresTemplate = $templateCache.get('featureList.html');

            var defaultWidth = 159;
            var columnDefs = [
                {
                    headerName: 'ID', field: 'id', hide: true, width: defaultWidth
                }, {
                    headerName: 'Status', field: 'status', hide: true, width: defaultWidth
                }, {
                    headerName: 'Name', field: 'name', width: defaultWidth
                }, {
                    headerName: 'Type', field: 'license', width: defaultWidth
                }, {
                    headerName: 'Vendor', field: 'vendor', width: defaultWidth
                }, {
                    headerName: 'Features',
                    field: 'features',
                    template: featuresTemplate,
                    width: defaultWidth
                }, {
                    headerName: 'Created', field: 'created', width: defaultWidth
                }, {
                    headerName: 'LastLogin', field: 'lastlogin', width: defaultWidth
                }, {
                    headerName: 'Actions',
                    field: 'actions',
                    template: organizationListActionTemplate,
                    width: 154
                }
            ];

            $scope.gridOptions = {
                columnDefs: columnDefs,
                enableSorting: true,
                enableFilter: true,
                rowSelection: 'multiple',
                enableColResize: true,
                angularCompileRows: true,
                rowHeight: 42,
                onRowClicked: function ($event) {
                    $state.go(ADMIN.ORGANIZATIONS_VIEW.stateName, {id: $event.data.id});
                }
            };


        })();

        $scope.$watch("commonReady", function (newVal) {
            if (!newVal) return;

            if (!$scope.allow['editOrganizations']) return $scope.reject();

            // init
            $scope.queryString = "";
            loadOrganizations();


            function reloadOrganizations() {
                loadOrganizations();
            }


            function loadOrganizations() {

                OrganizationService.vendorList().then(function (results) {

                    $scope.vendors = results.data.vendors;

                    aerosAdminApi.loadOrganizations().success(function (data) {
                        $scope.organizations = data.organizations;

                        var rowData = [];

                        angular.forEach($scope.organizations, function (organization) {

                            rowData.push({
                                id: organization.id,
                                name: organization.name,
                                status: organization.status,
                                license: organization.license,
                                vendor: organization.vendorRef
                                    ? OrganizationService.getNameByVendorRef(organization.vendorRef.key.raw.name,
                                        $scope.vendors) : 'AFL',
                                features: organization.featureRefs ? organization.featureRefs : undefined,
                                created: organization.created ? $filter('date')(organization.created, 'yyyy-MM-dd') : '',
                                lastlogin: organization.lastAccessed
                                    ? $filter('date')(organization.lastAccessed, 'yyyy-MM-dd') : '',
                                actions: ''
                            });

                        });

                        var filteredRowData = $filter('filter')(rowData, $scope.orgStatusFilter);
                        filteredRowData = $filter('filter')(filteredRowData, {name: $scope.queryString});

                        $scope.gridOptions.api.setRowData(filteredRowData);

                    });
                });
            }

            $scope.createNewOrganization = function () {
                createNewOrganizationModal.open().then(function () {
                    reloadOrganizations();
                });
            };
//TODO: Should we have a different admin api call, as we use to have, or can we use the newCustomer one
            /*    $scope.createNewOrganization = function () {
             createNewOrganizationModal.open().then(function (formFields) {
             var organization = formFields.organization;
             var user = formFields.user;
             aerosAdminApi.createOrganization(organization)
             .success(function (data) {
             Notification.success("Organization created.");

             user.organizationId = data.organizations[0].id;
             user.mergeRoles = true;

             aerosAdminApi.inviteUser(user).then(function () {
             Notification.success("Organization Administrator created.");
             });

             reloadOrganizations();
             });
             });
             };*/

            $scope.runSearch = function () {
                $scope.queryString = $("#queryString").val();
                reloadOrganizations();
            };

            $scope.deleteOrg = function (org) {
                $uibModal.open({
                    size: "md",
                    resolve: {
                        orgName: function () {
                            return org.name;
                        }
                    },
                    templateUrl: 'confirmDeleteModal.html',
                    controller: ["$scope", "orgName", function ($scope, orgName) {
                        $scope.orgName = orgName;
                    }]
                }).result.then(function () {
                    return aerosAdminApi.deleteOrganization(org.id).then(function (data) {
                        reloadOrganizations();
                        Notification.success("Organization '" + org.name + "' deleted.");
                    });
                });
            };

            $scope.editOrg = function (org) {
                createNewOrganizationModal.open(org).then(function (formFields) {
                    var organization = formFields.organization;
                    aerosAdminApi.updateOrganization(organization).success(function (data) {
                        reloadOrganizations();
                        Notification.success("Organization '" + organization.name + "' updated.");
                    });
                });
            };

            $scope.suspendOrg = function (org) {
                $uibModal.open({
                    size: "md",
                    resolve: {
                        orgName: function () {
                            return org.name;
                        }
                    },
                    templateUrl: 'confirmSuspendModal.html',
                    controller: ["$scope", "orgName", function ($scope, orgName) {
                        $scope.orgName = orgName;
                    }]
                }).result.then(function () {
                    return aerosAdminApi.suspendOrganization(org.id).then(function (data) {
                        reloadOrganizations();
                        Notification.success("Organization '" + org.name + "' suspended.");
                    });
                });
            };

            $scope.reactivateOrg = function (org) {
                $uibModal.open({
                    size: "md",
                    resolve: {
                        orgName: function () {
                            return org.name;
                        }
                    },
                    templateUrl: 'confirmActivateModal.html',
                    controller: ["$scope", "orgName", function ($scope, orgName) {
                        $scope.orgName = orgName;
                    }]
                }).result.then(function () {
                    return aerosAdminApi.activateOrganization(org.id).then(function (data) {
                        reloadOrganizations();
                        Notification.success("Organization '" + org.name + "' activated.");
                    });
                });
            };

            $scope.orgStatusFilter = function (org) {
                return org.status !== 'Deleted';
            };
        });
    }


})();


